import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    page: 0,
    title: "",
    menu: {
        expanded: false
    }
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        updateLayout: (state, action) => {
            const { page, title } = action.payload
            state.page = page;
            state.title = title;
        },
        updateMenuToggle: (state) => {
            state.menu.expanded = !state.menu.expanded;
        },
    }
});

export const { updateLayout, updateMenuToggle } = layoutSlice.actions;

export default layoutSlice.reducer

export const selectLayoutDetail = state => {
    return {
        title: state.layout.title,
        page: state.layout.page
    }
}

export const selectMenuExpanded = state => state.layout.menu.expanded;