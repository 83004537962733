import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getHubSpotAllUsers, processDataByHubSpotUserIds, selectHubSpotAllUsers, selectProcessDataByHubSpotUserIdsStatus, selectStatus, updateStatus } from '../reducerSlices/userFollowUpProcessSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus } from '../../../api/client';
import { accountService } from '../../accounts/services/accountService';
import GridLoader from '../../../component/Controls/loaders/gridLoader';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../component/logo/logo';


const UserFollowUpProcess = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = accountService.getAuthenticatedUser();
    const _users = useSelector(selectHubSpotAllUsers);
    const usersRequestStatus = useSelector(selectStatus);

    const processDataByHubSpotUserIdsStatus = useSelector(selectProcessDataByHubSpotUserIdsStatus);


    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const _loadUsers = async () => {
        await dispatch(getHubSpotAllUsers({}));
    }

    useEffect(() => {
        if (usersRequestStatus === fetchStatus.IDLE) {
            _loadUsers();
        }
    }, [usersRequestStatus, dispatch]);

    useEffect(() => {
        if (usersRequestStatus === fetchStatus.SUCCEEDED) {
            let _records = _users;
            setUsers(_records);
        }
    }, [usersRequestStatus]);

    // useEffect(()=>{
    //     alert( usersRequestStatus)
    // },[usersRequestStatus])

    const handleCheckboxChange = (userId) => {
        setSelectedUsers((prevSelected) =>
            prevSelected.includes(userId)
                ? prevSelected.filter((id) => id !== userId)
                : [...prevSelected, userId]
        );
    };


    const submitSelectedUsers = async () => {
        if (selectedUsers.length === 0) {
            alert('Please select at least one user for processing.');
            return;
        }

        dispatch(processDataByHubSpotUserIds({
            UserIds: selectedUsers,
        }));
    };

    useEffect(() => {
        if (processDataByHubSpotUserIdsStatus === fetchStatus.SUCCEEDED) {
            navigate('/thank-you')
        }
    }, [processDataByHubSpotUserIdsStatus]);

    const onDashboardClicked = () => {
        navigate("/dashboard");
    }


    return (
        <>
            {(usersRequestStatus === fetchStatus.LOADING ||
                processDataByHubSpotUserIdsStatus === fetchStatus.LOADING
            ) && <div className="app-container-loader">
                    <GridLoader />
                </div>}

            {/* <div className="container  mt-5"> */}
            {/* <div className="row>"> */}
            <div className="container alert alert-light new-card-ui p-5">
                {/* <h1>GetFollowUps</h1> */}
                {/* <Logo /> */}
                <div className="row align-items-center">
                    <div className="col-auto">
                        <Logo />
                        {/* <h1 className="mb-0">Dashboard</h1> */}
                    </div>
                    {/* <div className="col-auto ms-auto">
                        {accountService.isCompanySetup() === true &&
                            <button className="btn btn-primary" onClick={onDashboardClicked}>
                                <i class="fa fa-dashboard me-2"></i> Dashboard
                            </button>
                        }
                    </div> */}
                </div>

                <br></br>
                <br></br>
                <h3 className="text-center mb-4">User Filter For Get Followups Process</h3>
                <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Select</th>
                                <th scope="col">Owner ID</th>
                                <th scope="col">User ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            onChange={() => handleCheckboxChange(user.id)}
                                            checked={selectedUsers.includes(user.id)}
                                        />
                                    </td>
                                    <td>{user.id}</td>
                                    <td>{user.userId}</td>
                                    <td>{user.firstName} {user.lastName}</td>
                                    <td>{user.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>


                <div className="row">
                    <div className="col-auto">
                        <button
                            className="btn btn-primary mt-3"
                            onClick={submitSelectedUsers}
                            disabled={selectedUsers.length === 0}
                        >
                            Users For Filter
                        </button>
                    </div>
                </div>
            </div >
            {/* </div> */}

            {/* </div> */}
        </>
    );
};

export default UserFollowUpProcess;