import React from 'react'
import getfollowupsLogo from '../../assets/logo/getfollowups-logo.png';

function Logo() {
  return (
    <>
      <img src={getfollowupsLogo} className="nav-logo" alt="logo" />
    </>
  )
}

export default Logo