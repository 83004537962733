import React from 'react';
import './css/home.css';
import { useNavigate } from "react-router-dom";
import Logo from '../../component/logo/logo';

const Home = () => {
    const navigate = useNavigate();

    const handleConnectClick = () => {
        navigate("/login");
    }


    return (
        <>
            <div className="home-page">
                <header className="header">
                    {/* <h1>GetFollowUps</h1> */}
                    <Logo />
                </header>
                <main className="main-content">
                    <button onClick={handleConnectClick} className="connect-button">
                        Connect
                    </button>
                </main>
            </div>

            {/* <div className="login-container">
                <Logo />
                <br></br>
                <br></br>
                <main className="main-content">
                    <button onClick={handleConnectClick} className="connect-button">
                        Connect
                    </button>
                </main>
            </div> */}
        </>
    );
};

export default Home;
