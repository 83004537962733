import {
    createSlice,
    createAsyncThunk
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import Collection from '../../../component/Controls/paginators/collection';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        records: new Collection(),
        firstRecords: new Collection()
    },
    processDataByHubSpotUser: {
        status: fetchStatus.IDLE,
        error: null,
        processDataByHubSpotUserResource: undefined
    },
};

export const getHubSpotAllUsers = createAsyncThunk('userFollowUpProcess/getHubSpotAllUsers', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Process/getHubSpotAllOwners', userModel, getAxiosRequestConfig());
        const data = response.data ? response.data : {};
        let users = [];
        let totalItems = 0;
        if (data.Data && isJSON(data.Data)) {
            const parsedData = JSON.parse(data.Data);
            users = parsedData.items || [];
            totalItems = parsedData.totalItems || 0;
        }

        return {
            users: users,
            success: data.Success,
            totalItems: totalItems,
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const processDataByHubSpotUserIds = createAsyncThunk('userFollowUpProcess/processDataByHubSpotUserIds', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Process/processDataByHubSpotUserIds`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let processDataByHubSpotUser = undefined;
        if (data && isJSON(data)) {
            processDataByHubSpotUser = JSON.parse(data);
        }
        return {
            processDataByHubSpotUser: processDataByHubSpotUser,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const userFollowUpProcessSlice = createSlice({
    name: 'userFollowUpProcess',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getHubSpotAllUsers.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        })
        .addCase(getHubSpotAllUsers.fulfilled, (state, action) => {
                state.data.totalItems = action.payload.totalItems;
                state.data.records = action.payload.users;
                let _records = new Collection();
                _records.Add(action.payload.page, action.payload.users);

                state.data.isFiltered = false;
                state.data.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            })
            .addCase(getHubSpotAllUsers.rejected, (state, action) => {
                state.status = fetchStatus.FAILED;
                state.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            }).addCase(processDataByHubSpotUserIds.pending, (state, action) => {
                state.processDataByHubSpotUser.status = fetchStatus.LOADING;
            }).addCase(processDataByHubSpotUserIds.fulfilled, (state, action) => {
                state.processDataByHubSpotUser.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            }).addCase(processDataByHubSpotUserIds.rejected, (state, action) => {
                state.processDataByHubSpotUser.status = fetchStatus.FAILED;
                state.processDataByHubSpotUser.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            });
    }
});

export const {
    updateSingleStatus, updateStatus,
} = userFollowUpProcessSlice.actions;

export default userFollowUpProcessSlice.reducer

export const selectHubSpotAllUsers = state => state.userFollowUpProcess.data.records;
export const selectTotalItems = state => state.userFollowUpProcess.data.totalItems;
export const selectStatus = state => state.userFollowUpProcess.data.status;
export const selectError = state => state.userFollowUpProcess.data.error;

export const selectProcessDataByHubSpotUserIdsStatus = state => state.userFollowUpProcess.processDataByHubSpotUser.status;
export const selectProcessDataByHubSpotUserIdsError = state => state.userFollowUpProcess.processDataByHubSpotUser.error;