import axios from 'axios';

export const BajoAPI = axios.create({
    baseURL: `https://landerr-gettestapp.azurewebsites.net/api/` // Test Enviroment 
    // baseURL: `https://localhost:44366/api/`                       // Local Enviroment
});

export const fetchStatus = {
    IDLE: 'idle',
    LOADING: 'loading',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
    DONE: 'done'
}

export const masterTenantId = "GetFollowUps";
export const tenantId = "GetFollowUps";


export const redirectHubSpotMarketPlace = () => {
    const client_id = '5dcec149-d241-4fff-8fc2-ae6004225aa4';
    const redirect_uri = 'https://apps.getfollowups.com/callback';
    const scopes = 'crm.lists.read crm.lists.write crm.objects.contacts.read crm.objects.contacts.write crm.objects.owners.read crm.objects.users.read crm.schemas.contacts.read crm.schemas.contacts.write oauth sales-email-read';
    const authUrl = `https://app.hubspot.com/oauth/authorize?client_id=${encodeURIComponent(client_id)}&scope=${encodeURIComponent(scopes)}&redirect_uri=${encodeURIComponent(redirect_uri)}`;
    window.location.href = authUrl; 
};

export const linkedInOptions = {
    CLIENT_ID: '86lgfh6cinx3gb',
    REDIRECT_URL: `${window.location.origin}/linkedin/callback`
}