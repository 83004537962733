import React from 'react'

function SocialLoginButton(props) {
    return (
        <button className={`social-btn ${props.buttonclasses ? props.buttonclasses : ''}`}
            disabled={props.loading || props.disabled}
            onClick={(e) => props.onClick && props.onClick(e)}>
            {props.loading &&
                <i className="fas fa-spinner fa-spin me-2"></i>
            }
            {
                props.imgSrc &&
                <img src={props.imgSrc} style={{ width: '20px', height: '20px', marginLeft: '5px', marginRight: '10px' }} />
            }
            {
                props.faClasses &&
                <i className={`${props.faClasses} me-2`}></i>
            }
            {props.text ? props.text : "Default Button"}
        </button>
    )
}

export default SocialLoginButton