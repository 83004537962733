import React from 'react';
import Logo from '../../../component/logo/logo';

function ThankYou() {
  return (
    <div className="container text-center new-card-ui p-5">
      <div className="row">
        <div className="col-auto mb-5">
          <Logo />
        </div>
        <div className="col-12">
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading">Thank You!</h4>
            <p>Your submission has been received successfully.</p>
            <hr />
            <p className="mb-0">We appreciate your feedback and will get back to you shortly.</p>
          </div>
          <a href="https://app.hubspot.com" className="btn btn-primary mt-3">
            Go Back To Hubspot
          </a>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
