export const registerDetailsEN = {
    Default: " -- Select -- ",
    Title: "GET STARTED TODAY!",
    FirstName: "First Name",
    LastName: "Last Name",
    PhoneNumber: "Phone Number",
    Email: "Business Email",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
    NumberOfEmployees: "Number Of Employees",
    BirthDate: "Birth Date",
    FirstNameError: "Please enter first name",
    LastNameError: "Please enter last name",
    PhoneNumberError: "Please enter phone number",
    EmailError: "Please enter business email",
    PasswordError: "Please enter password",
    ConfirmPasswordError: "Please enter confirm password",
    NumberOfEmployeesError: "Please select number of employees",
    BirthDateError: "Please enter birth date",
    Register: "Register",
    RegisterTooltip: "Click to create an account",
    LoginPrompt: "Already have an account?",
    Login: "Login",
    PasswordMismatchError: "Password and Confirm Password did not match",
    PasswordLengthError: "Password should be at least 8 characters",
    CompanyName: "Company Name",
    CompanyNameError: "Please enter company name"
}