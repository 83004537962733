import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from '../component/layouts/layout';
import { accountService } from '../features/accounts/services/accountService';

const LayoutRoute = ({ component: Component, ...rest }) => {
    // return (
    //     <Outlet {...rest} render={props =>
    //         accountService.isAuthorized() ? (
    //             <Layout>
    //             <Component {...props} />
    //             </Layout>
    //             // ) : (<Navigate to={{ pathname: '/login', state: { returnUrl: props.location } }} />)} />
    //         ) : (<Navigate to={'/login'} />)} />
    // )

    return (
        accountService.isAuthorized() ?
            <Layout>
                <Outlet />
            </Layout>
            : <Navigate to={"/login"} />
    )
};

export default LayoutRoute;