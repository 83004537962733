import React, { useState, useEffect } from 'react'

import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
// import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { accountService } from '../../features/accounts/services/accountService';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectLayoutDetail } from '../layouts/reducerSlices/layoutSlice';


function TopNavBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");

  const user = accountService.getAuthenticatedUser();

  const layoutDetail = useSelector(selectLayoutDetail);

  useEffect(() => {
    if (layoutDetail) {
      setTitle(layoutDetail.title);
    }
  }, [layoutDetail]);


  const onLogoutClicked = (e) => {
    e.preventDefault();
    accountService.logout();
    window.location.reload();
  };

  const onReloadClicked = (e) => {
    e.preventDefault();
    window.location.reload();
  };


  return (
    <nav className="navbar navbar-expand-md px-3 top-nav-bar">
      <a className="btn border-0 menu-btn">
        {/* <HiOutlineMenuAlt2 /> */}
        <span className="navbar-toggler-icon"></span>
      </a>
      <div className="container-fluid">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link top-nav-bar-title-text" aria-current="page" href="#">{title}</a>
            </li>
          </ul>
          <form className="d-flex" role="search">
            {/* <input className="form-control input-search me-2" type="search" placeholder="Search" aria-label="Search" /> */}
          </form>
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item">
              <div className="top-nav-bar-notification">
                <i className="fa-solid fa-rotate cursor-pointer" title='Refresh' onClick={(e) => onReloadClicked(e)}></i>
              </div>
            </li>
            <li className="nav-item text-center">
              {/* <img src={boyFaceImg} className="menu-profile-photo" /> */}
              <img className="menu-profile-photo"
                src={user && user.photoPath ?
                  user.photoPath
                  : boyFaceImg
                }
              />
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle text-primary" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {/* <span>Thomas Foley</span> */}
                <span>{user && user.FirstName ? user.FirstName : ""} {user && user.LastName ? user.LastName : ""}</span>
              </a>
              <ul className="dropdown-menu dropdown-menu-lg-end">
                {/* <li><a className="dropdown-item" href="#" onClick={(e) => onATSClicked(e)}><i className="fa-solid fa-person-rays me-2"></i>ATS</a></li> */}
                {/* <li><a className="dropdown-item" href="#" onClick={(e) => onCRMClicked(e)}><i className="fa-solid fa-users me-2"></i>CRM</a></li> */}
                {/* <li><hr className="dropdown-divider" /></li> */}
                <li><a className="dropdown-item" href="#" onClick={(e) => onLogoutClicked(e)}>Log Out</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default TopNavBar