import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSetupExchangeCodeStatus, setupExchangeCode } from '../reducerSlices/accountsSlice';
import { accountService } from '../services/accountService';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../component/logo/logo';
import { fetchStatus } from '../../../api/client';

const Callback = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let storedACInfo = accountService.getAuthenticatedUser();
    const setupExchangeCodeStatus = useSelector(selectSetupExchangeCodeStatus);

    // Check if the ACInformation exists and is valid
    let isLoggedIn = accountService.isAuthenticated();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code'); // Get the authorization code from URL

        if (accountService.isAuthenticated() && code) {
            exchangeCodeForToken(code);
        }
    }, [isLoggedIn]);

    const exchangeCodeForToken = async (code) => {
        storedACInfo = JSON.stringify(storedACInfo);
        await dispatch(setupExchangeCode({
            code, storedACInfo
        }));
    }

    useEffect(() => {
        if (setupExchangeCodeStatus === fetchStatus.SUCCEEDED) {
            navigate("/settings/userFollowUpProcess");
        }
    }, [setupExchangeCodeStatus]);


    return (
        <>

            <div className="container text-center new-card-ui p-5">
                <div className="row">
                    <div className="col-auto mb-5">
                        <Logo />
                    </div>
                    <div className="col-12">
                        {/* {token ?
                            <>
                                <div className="alert alert-success" role="alert">
                                    <h3 className="alert-heading">Installation</h3>
                                    <h5 className="">Do not close or refresh this page.</h5>
                                    <hr />
                                    <p className="mb-0">Installation Complete, Please setup setting</p>
                                </div>

                            </>
                            : */}
                            <>
                                <div className="alert alert-warning" role="alert">
                                    <h3 className="alert-heading">Installation</h3>
                                    <h5 className="text-danger font-weight-bold">Do not close or refresh this page.</h5>
                                    <hr />
                                    <p className="mb-0">You’ll be notified when the installation is complete.</p>
                                </div>
                            </>
                            {/* } */}


                    </div>
                </div>
            </div>


            {/* <h2>Installation</h2>
            {accountService.isAuthenticated() ? (
                token ? (
                    <>
                        <p style={{ color: 'green', fontWeight: 'bold' }}>Installation Complete</p>
                        <p style={{ color: 'green', fontWeight: 'bold' }}>You can now safely close this page.</p>
                    </>
                ) : (
                    <>
                        <h3 style={{ color: 'red' }}>Do not close or refresh this page.</h3>
                        <h3 style={{ color: 'red' }}>You’ll be notified when the installation is complete.</h3>
                    </>
                )
            ) : (
                <h1>Please Login</h1>
            )} */}
        </>
    );
};

export default Callback;
