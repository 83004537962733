import { USER_KEY } from "./constants";

const getToken = () => {
    let user;
    if (localStorage.getItem(USER_KEY)) {
        user = JSON.parse(localStorage.getItem(USER_KEY));
    }
    return user ? `Bearer fake-jwt-token ${user.JwtToken}` : "";
}

export const getAxiosRequestConfig = () => {
    return {
        headers: {
            'Authorization': getToken()
        }
    }
}