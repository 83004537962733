import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';


function VerticalBarChart(props) {

    const backgroundColor = ['rgba(25, 135, 84, 0.2)', 'rgba(13, 202, 240, 0.2)', 'rgba(13, 110, 253, 0.2)', 'rgba(255, 193,7, 0.2)', 'rgba(45, 149, 240, 0.2)', 'rgba(33,37,41, 0.2)', 'rgba(220,53,69, 0.2)'];
    const borderColor = ['rgb(25, 135, 84)', 'rgb(13, 202, 240)', 'rgb(13, 110, 253)', 'rgb(255, 193, 7)', 'rgb(45, 149, 240)', 'rgb(33,37,41)', 'rgb(220,53,69)']

    const data = {
        labels: props.labelsChart,
        datasets: [
            {
                label: props.legendChart,
                data: props.valuesChart,
                backgroundColor: props.backgroundColor ? props.backgroundColor : backgroundColor,
                borderColor: props.backgroundColor ? '' : borderColor,
                borderWidth: props.backgroundColor ? 0 : 1
            },
        ],
    };
    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: { sort: true },
                datalabels: {
                    display: true,
                    color: 'blue',
                    labels: {
                        title: {
                            font: {
                                weight: 'bold'
                            }
                        },
                        value: {
                            color: 'green'
                        }
                    }
                }
            }
        },
        responsive: true,
        maintainAspectRatio: true,
    };

    return (
        <>
            <Bar data={data} plugins={[ChartDataLabels]} options={options} />
        </>
    );
}
export default VerticalBarChart;