import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    dashboardCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
        // refreshed: nanoid()
    },
};

export const getDashboardCounts = createAsyncThunk('dashboard/getDashboardCounts', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Process/getDashboardCounts', userModel, getAxiosRequestConfig());
        const data = response.data ? response.data.Data : "[]";
        let dashbaordData;
        if (isJSON(data)) {
            dashbaordData = JSON.parse(data);
        }
        return {
            dashbaordData: dashbaordData,
            success: response.data.success
        };

    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});


export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDashboardCounts.pending, (state, action) => {
            state.dashboardCount.status = fetchStatus.LOADING;
        })
            .addCase(getDashboardCounts.fulfilled, (state, action) => {
                state.dashboardCount.data = action.payload.dashbaordData;
                state.dashboardCount.status = fetchStatus.SUCCEEDED;
            })
            .addCase(getDashboardCounts.rejected, (state, action) => {
                state.dashboardCount.status = fetchStatus.FAILED;
                state.dashboardCount.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            });
    }
});

export const {
    updateStatus,
} = dashboardSlice.actions;

export default dashboardSlice.reducer

export const selectDashboardCounts = state => state.dashboard.dashboardCount.data;
export const selectDashboardCountsStatus = state => state.dashboard.dashboardCount.status;