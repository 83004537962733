export const ErrorCode = {
    EMAIL_IN_USE: 100,
    UNKNOWN_SERVER_ERROR: 101,
    INVALID_TOKEN: 102,
    INVALID_LOGIN_ATTEMPT: 103,
    UNAUTHORIZED: 104,
    INVALID_RESET_TOKEN: 105
};

export const USER_KEY = "THSBGDYDPKDH2233GTF";

export const Role = {
    ADMIN: "Admin",
    APPLICANT: "Applicant"
};

export const ForPage = {
    ADMIN: "Admin",
    USER: "User"
}

export const Operation = {
    CREATED: 0,
    UPDATED: 1,
    DELETED: 2
}


export const AccountPlan = {
    0: "Free",
    1: "Basic",
    2: "Standard",
    3: "Enterprise",
}