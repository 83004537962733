import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardCounts, selectDashboardCounts, selectDashboardCountsStatus } from '../reducerSlices/dashboardSlice';
import Logo from '../../../component/logo/logo';
import { fetchStatus } from '../../../api/client';
import DashboardCard from '../../../component/Controls/cards/dashboardCard';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import CustomVerticalBarChart from './component/CustomVerticalBarChart';


function Dashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        // if (!pageLoaded) {
        dispatch(updateLayout({
            page: 1,
            title: "Dashboard"
        }));
        // setPageLoaded(true);
        // }
    }, []);


    const dashboardCounts = useSelector(selectDashboardCounts);
    const dashboardCountsStatus = useSelector(selectDashboardCountsStatus);

    const _loadDashboardCounts = async () => {
        await dispatch(getDashboardCounts({}));

    }

    useEffect(() => {
        if (dashboardCountsStatus === fetchStatus.IDLE) {
            _loadDashboardCounts();
        }
    }, [dashboardCountsStatus]);

    useEffect(() => {
        if (dashboardCounts) {
            console.log('dashboardCounts -->', dashboardCounts)
            console.log('dashboardCounts -->', dashboardCounts["FollowUps: 1-1 Sales Approach"])
        }
    }, [dashboardCounts]);

    const onSettingClicked = () => {
        navigate("/settings/userFollowUpProcess");
    }

    return (
        <>
            {/* <div class="container">
                <div class="dashboard">
                    <div class="dashboard-section">
                        <h2 className="dashboard-section-title">Follow-ups</h2>
                        <DashboardCard
                            title="1-1 Sales Approach"
                            cardBackgroundClass="followup-1"
                            number={dashboardCounts ? dashboardCounts["FollowUps: 1-1 Sales Approach"] : "-1"}
                            isItemsLoading={dashboardCountsStatus} />
                        <DashboardCard
                            title="Nurture"
                            cardBackgroundClass="followup-nurture"
                            number={dashboardCounts ? dashboardCounts["FollowUps: Nurture"] : "-1"}
                            isItemsLoading={dashboardCountsStatus} />
                        <DashboardCard
                            title="Change Communication Channel"
                            cardBackgroundClass="followup-channel"
                            number={dashboardCounts ? dashboardCounts["FollowUps: Change Communication Channel"] : "-1"}
                            isItemsLoading={dashboardCountsStatus} />
                        <DashboardCard
                            title="Enrich"
                            cardBackgroundClass="followup-enrich"
                            number={dashboardCounts ? dashboardCounts["FollowUps: Enrich"] : "-1"}
                            isItemsLoading={dashboardCountsStatus} />
                    </div>

                    <div class="dashboard-section">
                        <h2 className="dashboard-section-title">Cold Leads</h2>
                        <DashboardCard
                            title="Sequence"
                            cardBackgroundClass="cold-sequence"
                            number={dashboardCounts ? dashboardCounts["Cold Lead: Sequence"] : "-1"}
                            isItemsLoading={dashboardCountsStatus} />
                        <DashboardCard
                            title="Disqualified"
                            cardBackgroundClass="cold-disqualified"
                            number={dashboardCounts ? dashboardCounts["Cold Lead: Disqualified"] : "-1"}
                            isItemsLoading={dashboardCountsStatus} />
                        <DashboardCard
                            title="Bogus"
                            cardBackgroundClass="cold-bogus"
                            number={dashboardCounts ? dashboardCounts["Cold Lead: Bogus"] : "-1"}
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                </div>
                <div class="dashborad-summary">
                    <h3 className="d-flex">Total Leads:
                        <span>
                            {dashboardCounts &&
                                dashboardCounts["FollowUps: 1-1 Sales Approach"]
                                + dashboardCounts["FollowUps: Nurture"]
                                + dashboardCounts["FollowUps: Change Communication Channel"]
                                + dashboardCounts["FollowUps: Enrich"]
                                + dashboardCounts["Cold Lead: Sequence"]
                                + dashboardCounts["Cold Lead: Disqualified"]
                                + dashboardCounts["Cold Lead: Bogus"]
                            }
                        </span></h3>
                </div>
            </div> */}


            <div className="default-container-wrapper p-3">
                <div className="row mt-2">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="1-1 Sales Approach"
                            cardBackgroundClass="followup-1"
                            iconBackgroundClass="bg-success"
                            number={dashboardCounts ? dashboardCounts["FollowUps: 1-1 Sales Approach"] : "-1"}
                            faIcon="fa-handshake"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="Nurture"
                            cardBackgroundClass="followup-nurture"
                            iconBackgroundClass="bg-info"
                            number={dashboardCounts ? dashboardCounts["FollowUps: Nurture"] : "-1"}
                            faIcon="fa-users"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="Change Communication Channel"
                            cardBackgroundClass="followup-channel"
                            iconBackgroundClass="bg-primary"
                            number={dashboardCounts ? dashboardCounts["FollowUps: Change Communication Channel"] : "-1"}
                            faIcon="fa-exchange-alt"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="Enrich"
                            cardBackgroundClass="followup-enrich"
                            iconBackgroundClass="bg-warning"
                            number={dashboardCounts ? dashboardCounts["FollowUps: Enrich"] : "-1"}
                            faIcon="fa-lightbulb"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                </div>
                <div className="row mt-2">

                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="Sequence"
                            cardBackgroundClass="cold-sequence"
                            iconBackgroundClass="bg-blue"
                            number={dashboardCounts ? dashboardCounts["Cold Lead: Sequence"] : "-1"}
                            faIcon="fa-bullhorn"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="Disqualified"
                            cardBackgroundClass="cold-disqualified"
                            iconBackgroundClass="bg-dark"
                            number={dashboardCounts ? dashboardCounts["Cold Lead: Disqualified"] : "-1"}
                            faIcon="fa-times-circle"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <DashboardCard
                            title="Bogus"
                            cardBackgroundClass="cold-bogus"
                            iconBackgroundClass="bg-danger"
                            number={dashboardCounts ? dashboardCounts["Cold Lead: Bogus"] : "-1"}
                            faIcon="fa-ban"
                            isItemsLoading={dashboardCountsStatus} />
                    </div>
                </div>

                <div className="row mt-3" >
                    <div className=" col-md-12 col-lg-8 col-xl-8 col-xxl-7 mb-4">
                        <div className="dashboard-container">
                            <div className="dashboard-body">
                                {/* {!registrationCount ?  */}
                                {/* <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>} */}
                                <div>
                                    <CustomVerticalBarChart
                                        // actBtn={actBtnApplicantRegistration}
                                        // showLocation='false'
                                        // startDate={Moment(startApplicantRegistration).format('YYYY-MM-DD')}
                                        // endDate={Moment(endApplicantRegistration).format('YYYY-MM-DD')}
                                        // locationOptionsValue={locationOptionsValue}
                                        // onLocationChange={(e) => setLocationApplicantRegistration(e.target.value)}

                                        // onWeekClicked={(e) => onWeekClickedApplicantRegistration(e)}
                                        // onMonthClicked={(e) => onMonthClickedApplicantRegistration(e)}
                                        // onTodayClicked={(e) => onTodayClickedApplicantRegistration(e)}

                                        // onFilter={(e) => onApplicantRegistrationDateChange(e)}

                                        title="Contacts Followups"
                                        legendChart="Followups"
                                        // labelsChart={registrationCount && JSON.parse(registrationCount[0].labelsChart)}
                                        // valuesChart={registrationCount && registrationCount[0].valuesChart.replace('[', '').replace(']', '').split(',')}
                                        // labelsChart={["COMPTON","ONTARIO","BUENA PARK","VAN NUYS","CITY OF INDUSTRY","CORONA","BELL","TORRANCE","BELLFLOWER","NORWALK","Alhambra","San Diego","San Jose","Philippines","Taiwan","testing new location"]}
                                        // valuesChart={[3,0,16,4,0,0,0,1,0,0,1,1,1,1,0,0]}
                                        labelsChart={dashboardCounts && Object.keys(dashboardCounts)}
                                        valuesChart={dashboardCounts && Object.values(dashboardCounts)}
                                        isItemsLoading={dashboardCountsStatus}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        </>
    )
}

export default Dashboard