import { configureStore } from "@reduxjs/toolkit";
import accountsSlice from "./features/accounts/reducerSlices/accountsSlice";
import layoutSlice from "./component/layouts/reducerSlices/layoutSlice";
import userFollowUpProcessSlice from "./features/settings/reducerSlices/userFollowUpProcessSlice";
import dashboardSlice from "./features/dashboard/reducerSlices/dashboardSlice";

export const store = configureStore({
    reducer: {
        accounts: accountsSlice,
        layout: layoutSlice,
        userFollowUpProcess: userFollowUpProcessSlice,
        dashboard: dashboardSlice
    }
});

export default store;