import * as React from 'react';

function RegisterButton(props) {
    return (
        <button
            type="button"
            className={`btn btn-primary ${props.RegisterButtonClasses ? props.RegisterButtonClasses : ""} ${props.loading ? "btn-disabled" : ""}`}
            title={props.loading ? "Please wait" : (props.title ? props.title : "Click to create account")}
            onClick={props.onRegisterClicked}
            disabled={props.disabled}>
            {props.text ? props.text : "Register"}{props.loading ? <i className="fa fa-spinner fa-spin ms-2"></i> : <i className='fa fa-chevron-right ms-2'></i>}
        </button>
    );
}

export default RegisterButton;